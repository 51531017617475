import { customElement } from 'lit/decorators.js';

/**
 * Registers a custom element if it doesn't already exist.
 * Used for registering web components.
 *
 * @param {string} tagName
 * @return {*}
 *
 * @example registerCustomElementSafely('ids-button')(IdsButton);
 */
export const registerCustomElementSafely = (tagName: string) => {
    return function (constructor: Parameters<ReturnType<typeof customElement>>[0]): void {
        if (!customElements.get(tagName)) {
            customElement(tagName)(constructor);
        }
    };
};

/**
 * Injects a stylesheet into the <head> element if it doesn't already exist.
 * Used for injecting CSS variables definitions by web components.
 *
 * @param {string} stylesheetId The ID of the stylesheet to inject.
 * @param {string} cssText The CSS text to inject.
 *
 * @example injectStyleInHead('my-stylesheet', ':root { --my-variable: red; }');
 */
export const injectStyleInHead = (stylesheetId: string, cssText: string): void => {
    if (!document.getElementById(stylesheetId)) {
        const style = document.createElement('style');
        style.id = stylesheetId;
        style.textContent = cssText;
        document.querySelector('head')?.prepend(style);
    }
};
