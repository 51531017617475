import {
    LitElement,
    PropertyDeclaration,
    PropertyDeclarations,
    TemplateResult,
    html,
    unsafeCSS,
} from 'lit';
import { Task } from '@lit/task';
import { query } from 'lit/decorators.js';

import { IdsIconFont, IdsIconFonts } from '@ids/icons/src/types';
import fontsJson from '@ids/icons/dist/fonts/index.json';
import { injectStyleInHead, registerCustomElementSafely } from '@ids/web-component-utilities';

import iconStyles from './icon-styles.css';
import iconVariables from './icon-variables.css';

const fontsDefinition: Readonly<IdsIconFonts> = fontsJson satisfies IdsIconFonts;

const CUSTOM_TAG = 'ids-icon';
injectStyleInHead(CUSTOM_TAG, iconVariables.cssText);
@registerCustomElementSafely(CUSTOM_TAG)
export class IdsIcon extends LitElement {
    static override styles = [
        iconStyles,
        unsafeCSS(
            Object.entries(fontsDefinition.fonts).reduce(
                (style, [font, { size }]) =>
                    `${style} :host([${font}]) {
                        font-family: ${font};
                        --font-size: var(--ids-icon-size-${size});
                        width: var(--ids-icon-size-${size});
                        min-width: var(--ids-icon-size-${size});
                        height: var(--ids-icon-size-${size});
                        min-height: var(--ids-icon-size-${size});
                    }`,
                '',
            ),
        ),
    ];

    static override get properties(): PropertyDeclarations {
        return Object.entries(fontsDefinition.fonts).reduce<Record<string, PropertyDeclaration>>(
            (props, [fontName, { codePoints }]) => {
                props[fontName] = { type: Boolean };
                Object.keys(codePoints).forEach((codePoint) => {
                    props[codePoint] = { type: Boolean };
                });
                return props;
            },
            {},
        );
    }

    private static documentIconFonts: WeakMap<IdsIconFont, FontFace | undefined> = new WeakMap(
        Object.values(fontsDefinition.fonts).map((font) => [font, undefined]),
    );

    private static getFontsBaseUrl = () =>
        document.documentElement.dataset.idsIconFontsBaseUrl || // <html data-ids-icon-fonts-base-url="local/fonts">
        document.head.dataset.idsIconFontsBaseUrl || // <head data-ids-icon-fonts-base-url="local/fonts">
        document.body.dataset.idsIconFontsBaseUrl || // <body data-ids-icon-fonts-base-url="local/fonts">
        fontsDefinition.url;

    private static findFont = (attributes: string[]) =>
        fontsDefinition.fonts[attributes.find((name) => fontsDefinition.fonts[name]) || ''];

    private static findSymbolCodePoint = (font: IdsIconFont | undefined, attributes: string[]) =>
        font?.codePoints[attributes.find((name) => font?.codePoints[name]) || ''];

    @query('.glyph', true)
    private glyphElement?: HTMLElement;

    private loadFontTask = new Task(this, {
        task: this.loadFont,
        args: () => Array.from(this.attributes).map((a) => a.name),
        autoRun: true,
    });

    private resizeObserver = new ResizeObserver((entries) => this.updateFontSize(entries));

    private loadFont(attributes: string[]): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const font = IdsIcon.findFont(attributes);
            const codePoint = IdsIcon.findSymbolCodePoint(font, attributes);
            const getErrorText = (error?: unknown) =>
                `Failed to load IDS icon: ${this.outerHTML} with attributes: ${attributes.join(
                    ', ',
                )}${error ? ` with error: ${error}` : ''}`;

            if (font && codePoint) {
                let fontFace = IdsIcon.documentIconFonts.get(font);
                if (!fontFace) {
                    fontFace = new FontFace(
                        font.name,
                        font.files
                            .map(
                                (f) =>
                                    `url("${IdsIcon.getFontsBaseUrl()}/${f.url}") format("${
                                        f.format
                                    }")`,
                            )
                            .join(', '),
                        {
                            display: 'block',
                            style: 'normal',
                            weight: 'normal',
                            stretch: 'normal',
                        },
                    );
                    IdsIcon.documentIconFonts.set(font, fontFace);
                    document.fonts.add(fontFace);
                    fontFace.load();
                }

                fontFace.loaded
                    .then(() => {
                        resolve(codePoint);
                    })
                    .catch((error) => reject(getErrorText(error)));
            } else {
                reject(getErrorText());
            }
        });
    }

    private updateFontSize(entries: ResizeObserverEntry[]): void {
        for (const entry of entries) {
            const { width, height } = entry.contentRect;
            // Set the font size to the smallest dimension to ensure the icon fits within the container
            this.glyphElement?.style.setProperty('--font-size', `${Math.min(width, height)}px`);
        }
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.resizeObserver.observe(this);
    }

    override disconnectedCallback(): void {
        this.resizeObserver.disconnect();
        super.disconnectedCallback();
    }

    override render(): TemplateResult {
        const emptyCodePoint = '';
        return html`
            <span class="glyph">
                ${this.loadFontTask.render({
                    initial: () => emptyCodePoint,
                    pending: () => emptyCodePoint,
                    complete: (codePoint) => codePoint,
                    error: (error) => {
                        console.error(error);
                        return emptyCodePoint;
                    },
                })}
            </span>
        `;
    }
}
